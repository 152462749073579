import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

const GameThumbnails = ({ games }) => {
  return (
    <div className="slot-game-more-games xl:w-[20vw] md:block hidden">
      <div className="text-[20px] text-center xl:text-[26px] xl:mx-10 xl:text-center self-center">
        What will you play next?
      </div>
      {games?.length > 0 ? (
        <div className="mote-games-items grid grid-cols-2 gap-x-4">
          {games.map(({ name, content: {slotIcon}, slug }) => (
            <div className="more-game-item">
              <Link to={`/${slug}`} className="max-w-[130px]">
                <GatsbyImage
                  image={getImage(slotIcon.gatsbyImageData)!}
                  alt=""
                />
                <div className="more-game-item-name !w-[90%] m-auto">{name}</div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <div className="mote-games-items flex xl:flex-col space-x-5 xl:space-x-0 xl:space-y-10 justify-center items-center">
          <div className="more-game-item">
            <Link to="/slot-games/forbidden-dragons/">
              <StaticImage
                src="../../images/slots/ForbiddenDragons.png"
                alt=""
                width={160}
              />
              <div className="more-game-item-name">Forbidden Dragons</div>
            </Link>
          </div>
          <div className="more-game-item">
            <Link to="/slot-games/invaders-from-the-planet-moolah/">
              <StaticImage
                src="../../images/slots/InvadersFromThePlanetMoolah.png"
                alt=""
                width={160}
              />
              <div className="more-game-item-name">
                Invaders From The Planet Moolah
              </div>
            </Link>
          </div>{" "}
          <div className="more-game-item">
            <Link to="/slot-games/zeus-ii/">
              <StaticImage
                src="../../images/slots/ZeusII.png"
                alt=""
                width={160}
              />
              <div className="more-game-item-name">Zeus II</div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
export default GameThumbnails;
