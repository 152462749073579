import React, { useRef } from "react"
import { graphql, Link, navigate } from "gatsby"
import SEO from "../components/seo"
import {
  ContentfulComposePageType,
  ContentfulSlotPageType,
} from "../types/contentful.types"
import BackgroundContainer from "../components/background-container"
import Footer from "../components/footer"
import DownloadPopup from "../components/download-popup"
import { useState } from "react"
import MenuMobile from "../components/menu/menu-mobile"
import Menu from "../components/menu/menu"
import Sections from "../components/sections/sections"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import AdjustLink from "../components/adjust-link"
import { isMobile, isTablet } from "react-device-detect"
import Carousel from "../components/carousel"
import VideoPlayer from "../components/video-player"
import BreadCrumbs from "../components/BreadCrumbs"
import GameThumbnails from "../components/GameThumbnails"

export const query = graphql`
  query SlotPageTemplateQuery($slug: String!) {
    contentfulGameThumbnailsSlots(
      slotPages: {elemMatch: {compose__page: {elemMatch: {slug: {in: [$slug]}}}}}
    ) {
      gameThumbnails: games {
          name
          content {
            ... on ContentfulSlotPage {
              id
              name
              slotIcon {
                gatsbyImageData
              }
            }
          }
          slug
        }
    }
    allContentfulSlotPage(
          filter: { compose__page: { elemMatch: { slug: { ne: null } } } }
        ) {
          edges {
            node {
              compose__page {
                slug
              }
            }
          }
        }
    contentfulSlotPage(compose__page: { elemMatch: { slug: { eq: $slug } } }) {
      name
      compose__page {
        impressionLink {
          impressionLink
        }
        title
        seo {
          keywords
          title
          no_index
          no_follow
          description
          socialImage {
            url
          }
        }
      }
      gameIframeUrl
      longGameIframeUrl {
        longGameIframeUrl
      }
      gameImage {
        title
        id
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        file {
          url
          contentType
        }
      }
      slotInfoLink
      sections {
        columns {
          __typename
          ... on ContentfulComponentSlotInfoTable {
            id
            tabTitle
            text {
              raw
            }
            software
            rtp
            reels
            paylines
            maxWager
            maxPayout
            jackpotType
            theme
            bonusRound
            jackpotTypeLinkTo {
              id
              compose__page {
                slug
              }
            }
            themeJson {
              internal {
                content
              }
            }
            softwareJson {
              internal {
                content
              }
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1920, quality: 20)
                }
              }
              title
            }
          }
          ... on ContentfulComponentSlotsWithFilter {
            id
            internalName
            slots {
              slotIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 300, quality: 20, layout: CONSTRAINED, placeholder: BLURRED)
                  }
                }
              }
              gameIframeUrl
              longGameIframeUrl {
                longGameIframeUrl
              }
              slotInfoLink
              compose__page {
                title
                slug
                createdAt
              }
              categories {
                id
                title
              }
            }
          }
          ... on ContentfulComponentSlotPageCards {
            id
            internalName
          }
          ... on ContentfulComponentSlotsTypes {
            id
            __typename
            slots {
              id
              compose__page {
                slug
                title
              }
              categories {
                title
                id
              }
              slotInfoLink
              gameIframeUrl
              longGameIframeUrl {
                longGameIframeUrl
              }
              slotIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                title
              }
              description {
                raw
              }
            }
          }
          ... on ContentfulComponentGallery {
            id
            __typename
            galleryType
            title
            media {
              title
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1920, quality: 20)
                }
              }
            }
          }
          ... on ContentfulComponentTextImage {
            id
            tabTitle
            __typename
            text {
              raw
              references {
                ... on ContentfulComponentImageLink {
                  id
                  __typename
                  contentful_id
                  title
                  linkToUrl
                  openInNewWindow
                  image {
                    localFile {
                      publicURL
                    }
                  }
                  linkToInnerPage {
                    slug
                  }
                }
                ... on ContentfulComponentStores {
                  id
                  contentful_id
                  __typename
                  storesList {
                    name
                    mobile {
                      mobile
                    }
                    desktop {
                      desktop
                    }
                  }
                }
              }
            }
            textBg
            imagesSide
            gallery {
              media {
                id
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1920, quality: 60)
                  }
                }
                title
                file {
                  contentType
                  url
                }
              }
            }
          }
          ... on ContentfulComponentSlotsTypes {
            id
            __typename
            description {
              description
            }
            showDescription
          }
          ... on ContentfulComponentCards {
            id
            title
            tabTitle
            additionalText {
              raw
            }
            bottomText {
              raw
            }
            iconPlace
            showTitles
            backgroundColor
            itemsInRow
            cards {
              title
              iconSizeOnMobile
              readMore {
                compose__page {
                  slug
                }
              }
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 80)
                  }
                }
                title
              }
              textAlignment
              textColor
              text {
                raw
              }
            }
          }
          ... on ContentfulComponentTextImageGroup {
            id
            __typename
            title
            spaceBetween
            description {
              raw
            }
            bottomText {
              raw
            }
            textImages {
              text {
                raw
                references {
                  ... on ContentfulComponentImageLink {
                    id
                    __typename
                    contentful_id
                    title
                    linkToUrl
                    openInNewWindow
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    linkToInnerPage {
                      slug
                    }
                  }
                  ... on ContentfulComponentStores {
                    contentful_id
                    __typename
                    storesList {
                      name
                      mobile {
                        mobile
                      }
                      desktop {
                        desktop
                      }
                    }
                  }
                }
              }
              textBg
              imagesSide
              gallery {
                media {
                  id
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 1920, quality: 85)
                    }
                  }
                  title
                  file {
                    contentType
                    url
                  }
                }
              }
            }
          }
          ... on ContentfulComponentSimpleText {
            contentful_id
            __typename
            id
            tabTitle
            backgroundColor
            text {
              raw
              references {
                ... on ContentfulFaQsSection {
                  __typename
                  id
                  contentful_id
                  title
                  faqs {
                    question
                    answer {
                      raw
                    }
                  }
                }
                ... on ContentfulComponentImageLink {
                  id
                  __typename
                  contentful_id
                  title
                  linkToUrl
                  openInNewWindow
                  image {
                    localFile {
                      publicURL
                    }
                  }
                  linkToInnerPage {
                    slug
                  }
                }
                ... on ContentfulComponentSlotsTypes {
                  id
                  __typename
                  contentful_id
                  slots {
                    id
                    compose__page {
                      slug
                      title
                    }
                    categories {
                      title
                      id
                    }
                    gameIframeUrl
                    longGameIframeUrl {
                      longGameIframeUrl
                    }
                    slotIcon {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                      title
                    }
                    description {
                      raw
                    }
                  }
                }
              }
            }
            images {
							localFile {
              	childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on ContentfulFaQsSection {
            __typename
            id
            contentful_id
            title
            faqs {
              question
              answer {
                raw
              }
            }
          }
          ... on ContentfulComponentYoutubeVideo {
            id
            youtubeUrl {
              youtubeUrl
            }
          }
        }
      }
      hero {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          title
          file {
            contentType
            url
          }
        }
        text {
          raw
        }
      }
    }
  }
`

const SlotPageTemplate = ({ data }) => {

  const { seo, title, impressionLink }: ContentfulComposePageType =
    data.contentfulSlotPage.compose__page[0]
  const {
    name,
    hero,
    sections,
    gameIframeUrl,
    longGameIframeUrl,
    gameImage,
    slotInfoLink,
  }: ContentfulSlotPageType = data.contentfulSlotPage

  const {edges: allSlots} = data.allContentfulSlotPage;

  const {gameThumbnails} = data.contentfulGameThumbnailsSlots || {};
  const [showDownloadPopup, setShowDownloadPopup] = useState<boolean>(false)
  const [shouldShowBanner, setShouldShowBanner] = useState(false);

  const [iframeUrl, setIframeUrl] = useState(longGameIframeUrl
    ? longGameIframeUrl.longGameIframeUrl
    : gameIframeUrl);

  const iframeContainerRef = useRef<any>()
  const onFullscreenClick = () => {
    iframeContainerRef.current.classList.add("fullscreen")
  }

  const onCloseClick = () => {
    iframeContainerRef.current.classList.remove("fullscreen")
  }

  seo["slotInfoCardsTitle"] = title.toUpperCase().replace("SLOT MACHINE", "")

  return (
    <>
      <SEO
        data={seo}
        shouldShowBanner={shouldShowBanner}
        setShouldShowBanner={setShouldShowBanner}
        impressionLink={impressionLink?.impressionLink}
      />
      {/* <CarnivalCruisePopUp onClick={() => setShowDownloadPopup(true)} /> */}

      <DownloadPopup
        onCloseClick={() => setShowDownloadPopup(false)}
        show={showDownloadPopup}
      />

      <div className={`app-container ${shouldShowBanner ? "show-banner" : ""}`}>
        <MenuMobile
          shouldShowBanner={shouldShowBanner}
        />
        <Menu onDownloadClick={() => setShowDownloadPopup(true)} />
        <main className="slot-page">
          <BackgroundContainer
            className="slot-info-top-background"
            image={hero.image}
          >
            <div className={`inner-container hero-content pt-28 px-10`}>
              <BreadCrumbs
                pipeline
                crumbs={[
                  {
                    label: "Home",
                    to: "/",
                    hasLink: true,
                  },
                  {
                    label: "Free Slots",
                    to: "/slot-games/",
                    hasLink: true,
                  },
                  {
                    label: name.toLocaleLowerCase().replace("- slot page", ""),
                    hasLink: false,
                  },
                ]}
                className="hidden xl:block text-[#ffcb2e] mb-4"
              />
              <div className="block xl:hidden mb-5">
                <Link
                  className="text-2xl text-[#ffcb2e]"
                  to="/slot-games/"
                >
                  {"<"} Free Slots
                </Link>
              </div>

              <h1 className="self-start text-[24px] md:text-[45px] pb-4 text-white font-bold">
                {title}
              </h1>
              <div className="flex gap-2 md:mb-6">
                <div className="slot-random-btn" onClick={() => {
                  const itemIndex = Math.floor(Math.random() * allSlots.length);
                  const slot = allSlots[itemIndex];
                  navigate(`/${slot.node.compose__page[0].slug}`)
                }} />
                <div className="slot-refresh-btn" onClick={() => {
                  const tempUrl = iframeUrl;
                  setIframeUrl('');
                  setTimeout(() => {
                    setIframeUrl(tempUrl)
                  });
                }} />
              </div>
            </div>
          </BackgroundContainer>
          <div
            className={`pt-10 xl:pt-0 xl:px-10 xl:pb-10`}
          >
            {(iframeUrl || gameImage?.length) && (
              <div className="slot-game-hero-container flex flex-col xl:flex-row xl:space-x-16 space-y-10 xl:space-y-0">
                <div className="flex-1 xl:w-[80vw] overflow-hidden">
                  <div className="flex flex-col h-full">
                    {gameImage?.length ? (
                      <Carousel
                        containerClass="slot-game-carousel"
                        edgesFade={false}
                        dots={true}
                        dotsOnImages={true}
                        showArrows={true}
                        slidesToScroll={1}
                        slidesToShow={1}
                        infinite={false}
                        data={gameImage.map((image) => {
                          return image.file.contentType === "video/mp4" ? (
                            <VideoPlayer url={image.file.url} />
                          ) : (
                            <GatsbyImage
                              key={image.id}
                              image={
                                getImage(
                                  image.localFile.childImageSharp
                                    .gatsbyImageData
                                )!
                              }
                              alt={image.title}
                              objectFit="fill"
                            />
                          );
                        })}
                        arrowsElements={{
                          left: (
                            <StaticImage
                              src="../../images/left-round-arrow.png"
                              alt=""
                            />
                          ),
                          right: (
                            <StaticImage
                              src="../../images/right-round-arrow.png"
                              alt=""
                            />
                          ),
                        }}
                        arrowsPosition={{ left: "0", right: "0" }}
                      />
                    ) : (
                      <div
                        ref={iframeContainerRef}
                        className="iframe-container relative min-h-[400px] xl:min-h-0"
                      >
                        <div className="full-screen-icon">
                          <button onClick={onFullscreenClick}>
                            <StaticImage
                              src="../images/full-screen.png"
                              alt="Close Popup"
                              width={60}
                            />
                          </button>
                        </div>
                        <button onClick={onCloseClick} className="slot-game-x">
                          X
                        </button>
                        <iframe
                          src={iframeUrl}
                          className="w-full flex-1 relative z-10"
                          scrolling="no"
                        />
                      </div>
                    )}
                    <div className="slot-game-play-for-real p-5 text-center">
                      <div className="text-white text-[20px] xl:text-[35px] font-semibold">
                        READY TO PLAY FOR REAL?
                      </div>
                      {isMobile || isTablet ? (
                        <AdjustLink slotPage={true}>
                          <StaticImage
                            className="cursor-pointer slot-info-get-the-app-button"
                            src="../images/get-the-app-button.png"
                            alt=""
                            width={300}
                          />
                        </AdjustLink>
                      ) : (
                        <button onClick={() => setShowDownloadPopup(true)}>
                          <StaticImage
                            className="cursor-pointer slot-info-get-the-app-button"
                            src="../images/get-the-app-button.png"
                            alt=""
                            width={300}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <GameThumbnails games={gameThumbnails} />
              </div>
            )}
          </div>
          {slotInfoLink && (
            <Sections
              createTabs={true}
              seo={seo}
              sections={sections}
              onDownloadClick={() => setShowDownloadPopup(true)}
            />
          )}
        </main>

        <Footer />
      </div>
    </>
  );
}

export default SlotPageTemplate
